[v-cloak]{
  display: none;
}


.add-new{
    display:inline-block;
    text-align:center;
    color: $gray-darker;

    &:hover, &:active, &:visited{
        color: $gray-darker;
    }
}


.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.text-left{
    text-align: left;
}


/*** replace glyphicons with font awesome icons ***/ 
.glyphicon {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

.glyphicon-chevron-left:before {
    content: "\f053";
}

.glyphicon-chevron-right:before {
    content: "\f054";
}

/** modal top **/ 
.modal-dialog{
    margin-top: 100px;
}



.margin-top-20{
    margin-top:20px;
}


//site wide error styling
.error-list{
    list-style:none;
    margin:0; 
    padding:0;
}


