// Update table styles
.table{
    > thead > tr > th {
        font-family: $font-family-serif;
        font-size: 12px; 
        font-color: $gray-darker;
        text-align:center;
    }

    > tbody > tr {
        &.bg-info{
            background-color: lighten($brand-info, 40%) !important;
        }
    }

    > tbody > tr > td {
        text-align:center;
        font-size: 14px; 
        vertical-align: middle;

        &.drawer{
            cursor: pointer;
        }

        small{
            display:block;
            font-size:10px;
        }
        
        //the action buttons on the forms
        ul{
            list-style:none;
            margin:0;
            padding:0;
            text-align:center;

            li {
                display:inline-block;
                margin:0 2px;

            }
        }

        fa{
            display:block;
        }

        a, a:hover, a:active, a:visited{
            color: $gray-darker;
            display:block;
            text-align:center;
            cursor:pointer;
        }
        
    }

    &.table-left > tbody > tr > td{
        text-align:left; 
    }

    i.green {
        color: #40D36E;
    }
}    

