//Custom header menu
.header-menu{
    margin-bottom:0;
    position: fixed;
    width: 100%;
    z-index:1100;

    &.navbar-default{
        .btn-link{
            color: $brand-primary;
        }

        .modal{
            h2{
                margin: 0;
                padding: 8px 6px;
            }
        }

    }

    .navbar-header{
        min-width:50%;
        
        h2{
            display:inline-block;
            color:#fff;       
            margin: 10px 0 0 0;
            padding: 0;
            font-size: 18px; 
            font-weight: 300;
            
            img{
                margin-right:5px;
            }
        }

        .navbar-brand{
            padding: 0; 
            width:230px;
            text-align:center;
            
            .vertical-image-align-helper {
                display: inline-block;
                height: 100%;
                vertical-align: middle;
            }

            img {
                max-height: 30px;
                width:auto;
                display:inline-block;
            }

        }

        a{
            height: 50px;
            line-height: 50px; 
        }

    }

    ul{
        display:inline; 
        margin-right:40px;
        
        li{
            cursor:pointer;
            font-size:14px;
            margin:0 8px;
            padding-top:10px;
            width:40px;
            position: relative; 
        }
    }

    h5{
        font-family: $font-family-sans-serif;
        font-weight: 300;
        margin:0 10px;
        cursor:pointer;

        .fa-gears{
            margin-left:10px;
        }
    }    

    h5, li{
        color: #fff;
        display:inline;
        height:50px;
        line-height:50px;
    }

    i{
        margin:0 2px;
        cursor:pointer;        
    }


    .notification-modal{
        .badge{
            position: absolute;
            top: 0px;
            right: -6px;
            padding: 2px 4px;
            font-weight: 300px;
            font-size:10px;
        }

        .badge-success{
            background-color: $brand-success;
        }

        .badge-danger{
            background-color: $brand-danger;
        }
    }

    .pagination{
        display:inline-block;
        margin-right:0;
    }

}
