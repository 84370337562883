//main page structure
#wrapper{
    position: relative;

    #page-content-wrapper{
        margin-left:50px;
        padding:10px 32px;
        padding-top: 60px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }


    /**
     *  Left side bar and toggle state
     *
     */

    #left-sidebar-wrapper{
        z-index: 1020;
        position: fixed;
        left: 200px;
        top:52px;
        width: 50px;
        height: 100%;
        margin-left: -200px;
        /* overflow-y: auto; */
        background: $medium-blue;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;

        h3, ul{
            display:none;
            font-family: $font-family-sans-serif;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
        }

        h3{
            font-size: 1em;
            margin:0px;
            padding-left:10px;
        }

        ul{
            margin:10px 0 20px 10px;
            &.open{
                display:block;

                &.min-menu{
                    position: absolute;
                    background: $medium-blue;
                    left: 50px;
                    width: 150px;
                    margin: -40px 0 0 0;
                    padding: 10px 15px 15px 30px;
                }
            }

            li{
                margin:3px 0;
                list-style-type: circle;
            }
        }

        i{
            display:inline-block;
            font-size:20px;
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;

            &.fa-chevron-right, &.fa-chevron-down{
                display:none;
            }

        }

        i,h3,a,li{
            color:#fff;
        }

        a{
            &:hover, &:active, &:visited{
                text-decoration:none;
            }
        }

        .menu-header{
          height:40px;
          line-height:40px;
          text-align:center;
          background-color: $dark-blue;
          position:relative;
          clear:both;


          & > a{
            display:block;
          }

          &:hover{
            background-color: $medium-blue;
          }

          &.menu-toggle{
            cursor: pointer;

            .fa-chevron-right, .fa-chevron-down{
                position:absolute;
                right:10px;
                top:30%;
                font-size:10px;
            }
          }
        }

        .navbar-toggle{
            display:block;
            float:right;
            margin-right: 5px;

            .icon-bar{
                border: 1px solid #fff;
            }
        }

    }

    &.left-toggled{
        #left-sidebar-wrapper{
            width: 200px;
            h3,i{
                display:inline-block;
                font-size: 12px;
            }

            ul.open{
                position: relative;
            }

            .menu-header{
                padding-left:20px;
                height:30px;
                line-height:30px;
                text-align: left;
            }
        }

        #page-content-wrapper{
            margin-left:200px;
        }
    }

    /**
     *  Right side bar menu and toggle state
     *
     */


    #right-sidebar-wrapper{
        z-index: 1000;
        position: fixed;
        right: -200px;
        width: 0;
        top: 51px;
        height: 100%;
        margin-right: 200px;
        overflow-y: auto;
        background: $medium-blue;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;

        .close{
            float:right;
            color: #fff;
            margin: 20px 20px 0 0;
            width: 0;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;

        }

        ul{
            margin: 60px 0 0 0;
            padding: 0 0 0 20px;

            li{
                list-style: none;
                padding:5px 3px;
                width: 0;
                min-height: 30px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;


                a, a:hover, a:visited, a:active{
                    color: #fff;
                }
            }
        }

        .assume{
            position:absolute;
            bottom:70px;

            padding:20px 10px;
            margin:10px;
            border-radius: $border-radius-base;
            background-color:#fff;
        }
    }


    &.right-toggled{
        #right-sidebar-wrapper{
            width: 200px;

            .close{
                width: auto;
            }

            ul li {
                width: 180px;
                color: $gray-lighter;
            }
        }

        #page-content-wrapper{
            margin-right: 200px;
        }

    }

    /**
     *  Side drawer and toggle state
     *
     */
    #side-drawer-wrapper{
        z-index: 1000;
        position: fixed;
        right: -300px;
        width: 0;
        top: 51px;
        height: 100%;
        margin-right: 300px;
        overflow-y: auto;
        background: $medium-blue;
        -webkit-transition: all 0.2s ease;
        -moz-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;

        .close{
            float:right;
            color: #fff;
            margin: 20px 20px 0 0;
            width: 0;
            -webkit-transition: all 0.2s ease;
            -moz-transition: all 0.2s ease;
            -o-transition: all 0.2s ease;
            transition: all 0.2s ease;

        }

    }


    &.side-toggled{
        #side-drawer-wrapper{
            width: 300px;

            .close{
                width: auto;
            }
        }

        #page-content-wrapper{
            margin-right: 300px;
        }

    }
}

