.breadcrumb {
  list-style: none;
  font-style: $font-family-serif;
  font-size: 11px;
  font-weight: 400;
  margin:0;
  padding-left:0;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 2px;
      color: $breadcrumb-color;
    }

    a{
        &:active, &:visited, &:hover{
            color: $breadcrumb-color;
        }
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
