.btn-group[justified] {
    display: table;
    width: 100%;
    table-layout: fixed;
        border-collapse: separate;
    > .btn,
    > .btn-group {
        float: none;
        display: table-cell;
        width: 1%;
    }
    > .btn-group .btn {
        width: 100%;
    }

    > .btn-group .dropdown-menu {
        left: auto;
    }
}

.form-group .datepicker {
    display: block;
}

.form-vertical-vue-strap-container.dropdown-search .dropdown-menu {
    max-height: 300px;
    // padding-top: 30px;
    overflow: auto;
    // .bs-searchbox {
    //     position: sticky;
    //     background: #fff;
    //     width: 100%;
    //     margin-top: -30px;
    //     margin-left: -5px;
    //     padding: 5px 10px;
    //     input {
    //         margin: 5px 0 0 5px;
    //     }
    // }
}