@media print {
    @page { size: landscape; }

    #wrapper.toggled #left-sidebar-wrapper, #wrapper #left-sidebar-wrapper {
        display:none;
    }

    #wrapper.toggled #page-content-wrapper, #wrapper #page-content-wrapper{
        margin-left:0;
        padding-top:0;
    }

    .breadcrumb{
        display:none;
    }

    .panel-info, .panel-form, .panel-result{
        border-top: 4px solid $gray; 
    }

    .current-deal .panel-body, .proposed-deal .panel-body {
        h2, h3{
            font-size:15px !important;
        }
    }

    .stacker{ 
        .actions{
            display:none;
        }

        .payments{
            h2{
                margin: 0; 
            }
        }

        .proposed-deal {
            .proposed-model-pick{
                margin: 20px 0;
            }
        }

        .affixed{        
            position:relative; 
            width: auto !important; 
            left: inherit !important; 
            top: inherit !important; 
        }

        .col-md-3, .col-md-9{
            width: 100%; 
        }

        .col-md-2{
            width: 16.6%;
            display:inline-block;
        }

        .current-deal{ 
            .form-group{
                display:inline-block; 
                width: 16.6%;
            }
        }
        
        .print-customer{ 
            .form-group{
                display:inline-block; 
                width: 33%;

                h3{
                    word-break: break-word;
                    margin: 5px 0 0 0;
                    padding: 0;
                    font-size: 18px;      

                    &.small{
                        font-size:14px; 
                    }
                }
            }
        }


    }


    

    .stacker .payments{
        display:none;   
    }

    .stacker .payments.fixed-menu{
        opacity: 1 !important; 
        position: relative; 
        display:block;
        top:initial; 
    }


}
