//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #1F71A1;
$brand-success:         #007D3E;
$brand-info:            #00C0EF;
$brand-warning:         #F0AD4E;
$brand-danger:          #DE3434;

$font-size-base:          14px;

$font-size-h1:            floor(($font-size-base * 1.3)); // ~18px
$font-size-h2:            floor(($font-size-base * 1.3)); // ~18px
$font-size-h3:            ceil(($font-size-base * 1.1)); // ~16px
$font-size-h4:            ceil(($font-size-base * 1.05)); // ~15px
$font-size-h5:            $font-size-base;
$font-size-h6:            ceil(($font-size-base * 0.85)); // ~12px


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'Source Sans Pro', sans-serif;
$font-family-serif:       'Roboto', sans-serif;

$headings-font-family:    $font-family-serif;

$light-blue:              #3C8CBC;
$medium-blue:             #033857;
$dark-blue:               #021C2C;
$yellow:                  #FFD86C;


//== Navbar
//
//##


// Basics of a navbar
$navbar-height:                    50px;
/*
$navbar-margin-bottom:             $line-height-computed !default;
*/
$navbar-border-radius:             0;
/*
$navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height:       340px !default;
*/

$navbar-default-color:             #fff;
$navbar-default-bg:                $light-blue;
$navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links

$navbar-default-link-color:                #fff;
$navbar-default-link-hover-color:          #fafafa;
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         #fafafa;
$navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color:       #ccc;
$navbar-default-link-disabled-bg:          transparent;



// Breadcrumbs
$breadcrumb-color:                         $gray-dark;
$breadcrumb-active-color:                  $gray;

//pagination
$pagination-color:                         $gray;

$pagination-hover-bg:                      $gray-lighter;
$pagination-hover-color:                   $gray-dark;
$pagination-active-border:                 darken($gray-lighter, 20%);

$pagination-active-bg:                     $gray-lighter;
$pagination-active-color:                  $gray-dark;
$pagination-active-border:                 darken($gray-lighter, 20%);

$pagination-disabled-color:                $gray-dark;
$pagination-disabled-bg:                   lighten($gray-lighter, 20%);
$pagination-disabled-border:               darken($gray-lighter, 20%);
