.fa-spin-hover:hover {
    transform: rotate(360deg);
}

.ratings {
    .fa-star,
    .fa-star-half-o {
        color: $brand-primary;
    }
}


.fa-with-counter{
    position: relative;
    .count {
        font-style: normal;
        font-family: $font-family-sans-serif;
        font-size: 0.5em;
        min-width: 2em;
        min-height: 2em;
        background-color: #fff;
        border-radius: 2em;
        color: $gray-base;
        display: block;
        position: absolute;
        top: -0.5em;
        right: -1.5em;
        padding: 0.4em;
        text-align: center;
        line-height: 1.3em;
        &.count-primary {background-color: $brand-primary;}
        &.count-success {background-color: $brand-success;}
        &.count-info {background-color: $brand-info;}
        &.count-warning {background-color: $brand-warning;}
        &.count-danger {background-color: $brand-danger;}
    }
}

@mixin icon-inline {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.dropdown-menu > li > a {
    span.glyphicon.glyphicon-ok.check-mark {
        @include icon-inline;
        &:before {
            content: "\f00c";
        }
    }
}