.modal {    
    z-index: 5000;


    transition: all 0.3s ease;

    &.in {
        background-color: rgba(0,0,0,0.5);
    }

    &.zoom .modal-dialog{
        -webkit-transform: scale(0.1);
        -moz-transform: scale(0.1);
        -ms-transform: scale(0.1);
        transform: scale(0.1);
        top: 300px;
        opacity: 0;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
    }

    &.zoom.in .modal-dialog {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transform: translate3d(0, -300px, 0);
        transform: translate3d(0, -300px, 0);
        opacity: 1;
    }

    .panel-heading{
        .panel-title{
            display:inline-block;
        }
    }

    .panel-body{
        p.alert {
          padding: 10px 20px;
        }
    }

}


