.btn-select{
    width:100%;
}

.btn-unstyled {
    padding: 0;
    background: transparent;
    vertical-align: bottom;
}

// Extra small grid
//
// input columns sized for extra small grid

@include make-input-columns(xs);


// Small grid
//
// input columns sized for small grid

@media (min-width: $screen-sm-min) {
  @include make-input-columns(sm);
}


// Medium grid
//
// input columns sized for medium grid

@media (min-width: $screen-md-min) {
  @include make-input-columns(md);
}


// Large grid
//
// input columns sized for large grid

@media (min-width: $screen-lg-min) {
  @include make-input-columns(lg);
}
