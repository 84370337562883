// Override variables and import bootstrap variables
@import "admin/variables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";


// Bootstrap mixins and custom mixins
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "admin/mixins";



// Reset and dependencies
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
//@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/pager";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/labels";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/badges";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/wells";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";


/*
 * IMPORT CUSTOM SASS FILES HERE
 * but the big plus here is you can now override bootstrap variables with our own settings,
 * rather than writing a new declaration which overrides the bootstrap one
 * eg bootstrap says
 * $headings-font-family:    inherit !default;
 *
 * Now, instead of doing h1 font-family, h2 font-family etc we can just override the variable
 */


@import "admin/navbar";
@import "admin/type";
@import "admin/lists";
@import "admin/forms";
@import "admin/vue-strap";
@import "admin/layout";
@import "admin/panels";
@import "admin/breadcrumbs";
@import "admin/tables";
@import "admin/common";
@import "admin/modals";
@import "admin/pagination";
@import "admin/font-awesome";
@import "admin/side-drawer";


@import "admin/print";

