.panel{
    border-bottom: 1px solid $gray-lighter;
    border-left: 1px solid $gray-lighter;
    border-right: 1px solid $gray-lighter;
    background-color: #fff;
    -webkit-box-shadow: 0px 10px 6px -8px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 6px -8px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 6px -8px rgba(0,0,0,0.2);
}

.panel-filter{
    border-top: 4px solid $yellow;

    h2{
        display:inline-block;
        margin:0;
        padding:0;
    }

    .panel-heading{
        .fa{
            float:right;
            cursor: pointer;
        }
    }

    .advanced-filters{
        margin-top:40px;
    }

    .utilities{
        text-align: right;
        margin-top: 40px;
        padding-left: 0;

        li{
            list-style:none;
            display:inline-block;
            margin:0 5px;

            a{
                cursor: pointer;
            }
        }
    }

    .manage-filters {
        margin: 0;
        padding:0;

        li {
            list-style: none;
            display:inline-block;
            margin:0 3px;

            span{
                display: block;
                font-size: 10px;
                color: #fff;
                background-color: $brand-info;
                padding: 2px 4px 2px 4px;
                border-radius: 4px;
                cursor:pointer;

               &:after{
                    font-family: FontAwesome;
                    content: "\f00d";
                    padding-left:3px;
                    cursor: pointer;
               }

            }
        }


    }

}

.panel-info{
    border-top: 4px solid $brand-success;
}


.panel-results{
    border-top: 4px solid $gray;
}

.panel-form{
    border-top: 4px solid $brand-info;
}
