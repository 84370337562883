/**
 *  Creates grid width columns for vertial forms. 
 *
 *
 */

@mixin input-calc-column($index, $class) {
    .input-col-#{$class}-#{$index} {
        width: percentage(($index / $grid-columns));
    }

}

// [converter] This is defined recursively in LESS, but Sass supports real loops
@mixin make-input-columns($class) {
  @for $i from 0 through $grid-columns {
    @include input-calc-column($i, $class);
  }
}


