#side-drawer-wrapper{
    color: #fff;
}

.side-drawer{
    margin:50px 0;

    .row{
        margin:0 10px;


        h2{
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
        }
    }

    .col-md-6, .col-md-12{
        margin:0;
        padding:0;
        height:65px;
        font-size:18px;
        line-height: 18px;
        padding:0 4px;
        word-wrap: break-word;

        h4{
            padding:0;
            margin:0 0 5px 0;
            font-weight: 200;
            font-size:12px;
            font-family: $font-family-sans-serif;
            color: $light-blue;
            line-height:normal;
        }

    }

    .col-md-12{
        height:auto;
        min-height:50px;
        line-height: auto;
        margin-bottom: 15px;
    }

    .customer-information{
        font-size:12px;
    }

}

.campaign-side-drawer-targets {
    padding: 10px;
    background: #fff;
    overflow: auto;
    max-height: 200px;
    border-radius: 3px;
    color: #000;
}